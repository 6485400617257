import React from 'react';
import MainLayout from '../layouts/MainLayout';
import CalendlyWidget from '../pageComponents/Custom/CalendlyWidget';
import { ConsultationContent } from '../pageComponents/Custom/ConsultationContent';
import { Seo } from '../components/Seo';

const ConsultationPageTemplate = ({ pageContext }: any) => {
  const seo = pageContext.seo;
  const title = pageContext.title;

  return (
    <MainLayout>
      <Seo
        title={seo.seoTitle ? seo.seoTitle : title}
        description={seo.seoDescription ? seo.seoDescription : ''}
      />

      <ConsultationContent data={pageContext} />
      <CalendlyWidget />
    </MainLayout>
  );
};

export default ConsultationPageTemplate;
