import React, { FC } from 'react';
import styled from 'styled-components';
import { InlineWidget } from 'react-calendly';

interface ContentProps {
  data?: {};
}

const CalendlyWidget: FC<ContentProps> = ({}) => {
  return (
    <ContentWrap>
      <InlineWidget
        url="https://calendly.com/jan-bal/consultation?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=ff1d44"
        pageSettings={{ primaryColor: 'ff1e44' }}
        styles={{ height: 650, minWidth: 320 }}
      />
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  padding-bottom: 30px;
`;

export default CalendlyWidget;
