import React, { FC } from 'react';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { Container } from '../../../components/Container';

interface ContentProps {
  data: {
    content: string;
    title: string;
  };
}

const ConsultationContent: FC<ContentProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <ContentWrap>
          <Title>{data.title}</Title>
          <DescriptionWrap dangerouslySetInnerHTML={{ __html: data.content }} />
        </ContentWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.red};
  // min-height: 559px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 600px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    align-items: center;
  }
  @media (max-width: 600px) {
    align-items: flex-start;
    width: 100%;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.white};
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 41px;
  }
`;

const DescriptionWrap = styled.div`
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  margin-bottom: 56px;
  p {
    margin-bottom: 30px;
    max-width: 630px;
    text-align: left;
  }
  li {
    padding-left: 21px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 11px;
      height: 8px;
      background: url('/images/check.svg') no-repeat center;
      background-size: cover;
    }
  }
  ul {
    margin-bottom: 30px;
  }

  p,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 40px;
    p {
      text-align: left;
    }
  }
  @media (max-width: 400px) {
    max-width: 315px;
  }
`;

export default ConsultationContent;
